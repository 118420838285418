import { Fragment } from "react";
import Head from "./head";
const About = () => {


  let bios = [
    {
      img: "bryan.png",
      name: "Bryan Hill",
      inst: "Vocals, Guitar",
      bio: "Bryan is an international superstar, far too busy to provide us peons with his biography. But he did play Guy in Once, and then he ended up stuck with us.",
    },
    {
      img: "rich.png",
      name: "Rich Phillips",
      inst: "Vocals, Guitar, Mandolin, Banjo, Bass",
      bio: "Rich is a New Jersey based singer, songwriter and guitartist. He holds a B.Mus. in Jazz Composition and Arranging from Berklee College of Music, and an MBA from Rider University. He began playing at the age of eight because it was already clear he was not cut out for lumberjacking. He resides in Hamilton, NJ with his wife and daughters, and works in Information Technology. He has appeared in a slew of NJ cover bands and in supporting roles in local theater.",
      web: <a href="https://www.youtube.com/channel/UCuh3QRN6Ufldx1C_XzilmcQ">YouTube Channel</a>,
    },
    {
      img: "amanda.png",
      name: "Amanda Santos",
      inst: "Vocals, Violin, Guitar",
      bio: "A trained opera singer, Amanda has spent the majority of her career performing roles like Madame Butterfly and Tosca with opera companies all over the country. They have also done a bit of theater, most recently performing as violin-wielding Reza in the musical Once and as the guitar-playing ex-convict Bitsy Mae Harling in the play Sordid Lives (sound familiar?) Mostly though, they enjoy spending time with their husband and three kids, serving on the local school board, teaching other singers, and making music.",
    },
    {
      img: "danny.png",
      name: "Danny Santos",
      inst: "Percussion",
      bio: "A trained, ocean-dwelling opera singer, he sacrificed his enchanting voice to live on land, where he met his one true love, and learned to communicate by beating on drums.",
    },
    {
      img: "aidan.png",
      name: "Aidan Stallworth-Glitz",
      inst: "Vocals, Bass, Guitar, Piano",
      bio: "Aidan, known by his code name \"SG\", is an International Man of Mystery With Amazing Hair, and master of Interpretive Dance. That's all we're allowed to say.",
    },
    {
      img: "danielle.png",
      name: "Danielle Steward",
      inst: "Vocals, Guitar, Banjo, Ukulele, Piano",
      bio: "Danielle is a New Jersey based singer/songwriter. She grew up in a musical family and began playing the guitar at age 13. Since then, she's been writing songs and performing throughout the NYC / Philly / NJ area and beyond. Over the years she has developed an acoustic folksy style with an emotive voice and engaging tales. Albums include Off the Tracks,Traveling On, and Shipwrecked. In addition, she's had roles in community theater productions of American Idiot, Sunset Boulevard, Once, and Sordid Lives. Danielle is thrilled to be a part of Sordid Vibes, a collaboration of musicians brought together by a love of music, late night jams, and community theater.",
      web: <a href="http://www.daniellesteward.com">www.daniellesteward.com</a>,
    },
    
  ];


  const shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;
  
    while (currentIndex != 0) {
  
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  bios = shuffle(bios);

  return (
    <Fragment>
        <Head />

        <h2>MEET THE VIBES</h2>

        <table className="bios">
          <tbody>

            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>


            {
              bios.map((bio, i) => <tr key={i}>

                {i % 2 == 0 && <td>
                  <img src={bio.img} alt={bio.name}/>
                </td> }

                <td colSpan={2}>
                 <span className="bio-name">{bio.name}</span>
                  <span className="bio-instr">{bio.inst}</span>
                  <br/>
                  <span className="bio-bio">
                    {bio.bio}
                  </span>
                </td> 

                {i % 2 == 1 && <td>
                  <img src={bio.img} alt={bio.name}/>
                </td> }

              </tr>)
            }



          </tbody>
        </table>

        <table className="bios-mobile">
          <tbody>


            {
              bios.map((bio, i) => <Fragment key={i}>
                <tr>

                <td>
                  <img src={bio.img} alt={bio.name}/>
                </td>
                </tr><tr>

                <td>
                 <span className="bio-name">{bio.name}</span>
                  <span className="bio-instr">{bio.inst}</span>
                  <br/>
                  <span className="bio-bio">
                    {bio.bio}
                  </span>
                </td> 


              </tr>
              </Fragment>)
            }



          </tbody>
        </table>


    </Fragment>
);
}

export default About;