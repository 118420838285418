import { Fragment } from "react";
import Head from "./head";

const Tour = () => {


  const gigs = [
    {
      date: new Date("10/7/2023"),
      time: "2:00 PM - 3:00 PM",
      title: <a href="https://www.lawrencevillemainstreet.com/calendar-of-events">Lawrenceville Main Street - Fall Arts Festival</a>,
      location: <div>
          <div>Craven Lane</div>
          <div>Lawrenceville, NJ</div>
      </div>,
    },



  ];



  let today = new Date();
  today.setHours(0, 0, 0, 0);

  gigs.map(g => {g.date.setHours(0,0,0,0);});


  return (
    <Fragment>
        <Head />
        <div className="gradient1">
          SUPER-COLOSSAL TOUR OF THE GALAXY AND BEYOND
        </div>
        <div>&nbsp;</div>


        {
          gigs
            .filter(g => g.date >= today) 
            .map(g => <div className="tour-entry">
            <div>{g.date.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} - {g.time}</div>
            <div>{g.title}</div>
            <div>{g.location}</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </div>
          )
        }

    </Fragment>
);
}
export default Tour;